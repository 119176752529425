// import router from "@/router";
// import { reactive } from "vue";
import { db } from "@/firebase";
import {
  collection,
  query,
  // where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
// let _ = require("lodash");

const Forms = {
  namespaced: true,
  state: () => ({
    applicationList: {},
    infoSessionList: {},
    contactList: {},
    unsubscribeApplication: null,
    unsubscribeInfoSession: null,
    unsubscribeContact: null,
  }),
  //   Methods
  mutations: {
    loadApplicationList(state) {
      state.applicationList = {};
      const q = query(
        collection(db, "applicationForms"),
        orderBy("submittedOn", "desc")
        // where("status", "==", "publish")
      );
      if (state.unsubscribeApplication != null) {
        state.unsubscribeApplication();
      }
      state.unsubscribeApplication = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          state.applicationList[doc.id] = data;
        });
      });
      // console.log(state.list);
    },
    loadInfoSessionList(state) {
      state.infoSessionList = {};
      const q = query(
        collection(db, "infoSessionForms"),
        orderBy("submittedOn", "desc")
        // where("status", "==", "publish")
      );
      if (state.unsubscribeInfoSession != null) {
        state.unsubscribeInfoSession();
      }
      state.unsubscribeInfoSession = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          state.infoSessionList[doc.id] = data;
        });
      });
      // console.log(state.list);
    },
    loadContactList(state) {
      state.contactList = {};
      const q = query(
        collection(db, "contactForms"),
        orderBy("submittedOn", "desc")
        // where("status", "==", "publish")
      );
      if (state.unsubscribeContact != null) {
        state.unsubscribeContact();
      }
      state.unsubscribeContact = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          state.contactList[doc.id] = data;
        });
      });
      // console.log(state.list);
    },
    deleteApplication(state, id) {
      delete state.applicationList[id];
    },
    deleteInfoSession(state, id) {
      delete state.infoSessionList[id];
    },
    deleteContact(state, id) {
      delete state.contactList[id];
    },
  },
  actions: {},
  //   Computed Values
  getters: {},
};

export default Forms;
