import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import DesignSystem from "../design-system/vue.js";

let app;
onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App);
    // Design System component registration
    DesignSystem.register(app);

    app.use(store).use(router).mount("#app");
  }
});
