<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>
<script>
import {getAuth} from "firebase/auth"
export default {
  mounted() {
    var x = this;
    this.$store.commit("resizeWindowEvent");
    window.addEventListener(
      "resize",
      function() {
        x.$store.commit("resizeWindowEvent");
      },
      { passive: true }
    );
    const auth = getAuth();
    if(auth.currentUser != null){
      x.$store.commit("loadUserData");
    }
  },
};
</script>
<style lang="scss">
// @import "design-system/assets/scss/core";
@import "design-system/assets/scss/standard";
</style>
