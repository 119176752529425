<template>
  <div id="app">
    <List el="xsm" class="login">
      <img src="../assets/logo/alphaleaf_logo_color.svg" id="logo" alt="logo" el="logo">
      
      <!-- <Text el="h4" style="text-align: center;">Sign in to your portal</Text> -->
      <List el="xsm"></List>
      <!-- <div el="input-list">
        <div el="list" style="--gap: 16px">
          <label for="inputID" el="input-label">
            <div el="sm-txt label">Email</div>
            <input type="text" name="email" placeholder="Email" el />
          </label>
          <label for="inputID" el="input-label">
            <div el="sm-txt label">Password</div>
            <input type="password" name="password" placeholder="Password" el />
          </label>
        </div>
      </div>
      <Text el="p" style="text-align: center">OR</Text> -->
      <Btn class="microsoft-sign-in" @click="microsoftSignIn">
        <img
          src="../assets/icons/microsoft.svg"
          alt="Microsoft logo"
          loading="lazy"
        />
        Microsoft Sign In
      </Btn>
      <div v-if="login.error != ''" style="color: red">
        {{ login.error }}
      </div>
    </List>
  </div>
</template>

<script>
// @ is an alias to /src
import router from "../router";
import { auth } from "../firebase";

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      login: {
        username: "",
        password: "",
        error: "",
      },
    };
  },
  methods: {
    microsoftSignIn() {
      this.$store.commit("microsoftSignIn");
    },
  },
  mounted(){
    if(auth.currentUser != null){
      router.push('/dashboard');
    }
  }
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
  background-image: url(../assets/backgrounds/alphaleaf_section_light_logo.jpg);
}
.login {
  max-width: 600px;
  --bg-color: #fff;
  border-radius: 24px;
  padding: 45px;
  --gap: 24px;
  width: 364px;
  z-index: 2;
  --border:5px solid var(--color-13);
  margin:auto;
}
.microsoft-sign-in {
  --bg-color: #000;
  --hover-bg-color: #222;
  --color: #fff;
  font-weight: var(--font-medium);
  grid-gap: 8px;
  --height: 40px;
  /* border:1px solid hsla(0,0%,100%,1); */
  /* box-shadow:0 0 0 3px hsla(0,0%,100%,0.7); */
}
.microsoft-sign-in img {
  height: 20px;
}
#app {
  // background-image: linear-gradient(hsla(0, 0%, 0%, 0.5), hsla(0, 0%, 0%, 1)),
  //   url(../assets/img/bg-optimized.jpg);
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: fixed;
  display: grid;
  background-color: #f3f5fb;
  background-image: url(../assets/backgrounds/alphaleaf_section_light_logo.jpg);
  min-height: 500px;
  overflow: auto;
}
#logo {
  width: 100%;
  height: 80px;
}
label [el*="label"] {
  font-weight: bold !important;
}

@media (max-width: 800px) {
}
</style>
