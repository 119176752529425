import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import { auth } from "../firebase";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/docs",
    name: "Docs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Docs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/submit-payment",
    name: "Payment",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/SETpayment.vue"),
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: function() {
  //     return import("../views/Login.vue");
  //   },
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: () => import("../views/Assignments.vue"),
      },
      {
        path: "account",
        component: () => import("../views/Account.vue"),
      },
      {
        path: "modules",
        component: () => import("../views/Modules.vue"),
      },
      {
        path: "resources",
        component: () => import("../views/Resources.vue"),
      },
      {
        path: "users",
        component: () => import("../views/Users.vue"),
      },
      {
        path: "modules/:id",
        components: {
          default: () => import("../views/Modules.vue"),
          section: () => import("../views/ViewModule.vue"),
        },
      },
      {
        path: "forms",
        component: () => import("../views/Forms.vue"),
      },
      {
        path: "forms/applications/view/:id",
        components: {
          default: () => import("../views/Forms.vue"),
          section: () => import("../views/ViewApplication.vue"),
        },
      },
      {
        path: "forms/infosession/view/:id",
        components: {
          default: () => import("../views/Forms.vue"),
          section: () => import("../views/ViewInfoSession.vue"),
        },
      },
      {
        path: "forms/contact/view/:id",
        components: {
          default: () => import("../views/Forms.vue"),
          section: () => import("../views/ViewContact.vue"),
        },
      },
      {
        path: "recordings",
        component: () => import("../views/Recordings.vue"),
      },
      {
        path: "recordings/upload",
        components: {
          default: () => import("../views/Recordings.vue"),
          section: () => import("../views/UploadRecording.vue"),
        },
      },
      {
        path: "recordings/edit/:id",
        components: {
          default: () => import("../views/Recordings.vue"),
          section: () => import("../views/UploadRecording.vue"),
        },
      },
      {
        path: "recordings/view/:id",
        components: {
          default: () => import("../views/Recordings.vue"),
          section: () => import("../views/ViewRecording.vue"),
        },
      },
      {
        path: "learning",
        component: () => import("../views/Learning.vue"),
      },
      {
        path: "learning/upload",
        components: {
          default: () => import("../views/Learning.vue"),
          section: () => import("../views/UploadLearning.vue"),
        },
      },
      {
        path: "learning/view/:id",
        components: {
          default: () => import("../views/Learning.vue"),
          section: () => import("../views/ViewLearning.vue"),
        },
      },
      {
        path: "quiz",
        component: () => import("../views/Quiz.vue"),
      },
      {
        path: "quiz/upload",
        components: {
          default: () => import("../views/Quiz.vue"),
          section: () => import("../views/UploadQuiz.vue"),
        },
      },
      {
        path: "quiz/view/:id",
        components: {
          default: () => import("../views/Quiz.vue"),
          section: () => import("../views/ViewQuiz.vue"),
        },
      },
      {
        path: "activity",
        component: () => import("../views/Activity.vue"),
      },
      {
        path: "assignments",
        component: () => import("../views/Assignments.vue"),
      },
      {
        path: "assignments/upload",
        components: {
          default: () => import("../views/Assignments.vue"),
          section: () => import("../views/UploadAssignment.vue"),
        },
      },
      {
        path: "assignments/view/:id",
        components: {
          default: () => import("../views/Assignments.vue"),
          section: () => import("../views/ViewAssignment.vue"),
        },
      },
      {
        path: "referrals",
        component: () => import("../views/Referrals.vue"),
      },
      {
        path: "referrals/create",
        components: {
          default: () => import("../views/Referrals.vue"),
          section: () => import("../views/CreateReferrer.vue"),
        },
      },
      {
        path: "referrals/view/:id",
        components: {
          default: () => import("../views/Referrals.vue"),
          section: () => import("../views/ViewReferrer.vue"),
        },
      },
      {
        path: "files",
        component: () => import("../views/Files.vue"),
      },
      {
        path: "files/view/:path",
        name: "FilePreview",
        components: {
          default: () => import("../views/Files.vue"),
          section: () => import("../views/ViewFilePreview.vue"),
        },
      },
    ],
  },
  {
    path: "/file/:collection/:path/:filename",
    name: "File",
    component: () => import("../views/ViewFile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/preview/:path",
  //   name: "FilePreview",
  //   component: () => import("../views/ViewFilePreview.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/vscode",
    name: "VScode",
    component: () => import("../views/ViewVScode.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/typefu",
    name: "TypeFu",
    component: () => import("../views/ViewTypeFu.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/library",
    name: "Library",
    component: () => import("../views/ViewLibrary.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/library/view",
    beforeEnter() {
      // logic here
      window.location.href = "https://alphabase-origina.pages.dev/";
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  // {
  // 	path: "/library/*",
  // 	meta: {
  // 		requiresAuth: true,
  // 	},
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/");
  } else {
    next();
  }
});

export default router;
