// import router from "@/router";
// import { reactive } from "vue";
import { db } from "@/firebase";
import {
  collection,
  query,
  // where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
// let _ = require("lodash");

const Learning = {
  namespaced: true,
  state: () => ({
    list: {},
    unsubscribe: null,
  }),
  //   Methods
  mutations: {
    loadList(state) {
      state.list = {};
      const q = query(
        collection(db, "learning"),
        orderBy("date", "desc")
        // where("status", "==", "publish")
      );
      if (state.unsubscribe != null) {
        state.unsubscribe();
      }
      state.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          state.list[doc.id] = data;
        });
      });
      // console.log(state.list);
    },
    delete(state, id) {
      delete state.list[id];
    },
  },
  actions: {},
  //   Computed Values
  getters: {},
};

export default Learning;
