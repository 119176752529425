import { createStore } from "vuex";
import router from "../router";
import "../firebase";
let startsWith = require("lodash/startsWith");
let findKey = require("lodash/findKey");
let filter = require("lodash/filter");

import Assignments from "./Assignments";
import Activity from "./Activity";
import Forms from "./Forms";
import Learning from "./Learning";
import Recordings from "./Recordings";
import Referrals from "./Referrals";
import Quiz from "./Quiz";

import {
	getAuth,
	signInWithPopup,
	OAuthProvider,
	onAuthStateChanged,
	signOut,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "@/firebase";

const Store = {
	state: () => ({
		window: {
			width: 1366,
			timeout: null,
		},
		groups: {
			active: "SET 5",
			list: ["SET 3", "SET 4", "SET 5"],
			admin: [
				"info@alphaleaf.tech",
				"fawad@alphaleaf.tech",
				"z@alphaleaf.tech",
				"nijat@alphaleaf.tech",
				"zkim@alphaleaf.tech",
			],
		},
		user: {
			profileImg: null,
			displayName: "John Doe",
			email: "test@email.com",
		},
		layout: {
			header: {
				accountDropdown: false,
				groupDropdown: false,
			},
			nav: {
				active: "modules",
				collapse: false,
				menu: {
					// manageContentLibrary:{
					//     label:'Content Library',
					//     icon:'dashboard',
					// },
					videos: {
						label: "Recordings",
						icon: "play_arrow",
						path: "/dashboard/recordings",
						key: "videos",
					},
					// modules: {
					//   label: "Readings",
					//   icon: "dashboard",
					//   path: "/dashboard/modules",
					//   key: "modules",
					// },

					learning: {
						label: "Learning",
						icon: "play_lesson",
						path: "/dashboard/learning",
						key: "learning",
					},
					quiz: {
						label: "Quiz",
						icon: "school",
						path: "/dashboard/quiz",
						key: "quiz",
					},
					assignments: {
						label: "Assignments",
						icon: "history_edu",
						path: "/dashboard/assignments",
						key: "assignments",
					},
					referrals: {
						label: "Referrals",
						icon: "loyalty",
						path: "/dashboard/referrals",
						admin: true,
						key: "referrals",
					},
					resources: {
						label: "Resources",
						icon: "star",
						path: "/dashboard/resources",
						key: "resources",
					},
					forms: {
						label: "Forms",
						icon: "description",
						path: "/dashboard/forms",
						admin: true,
						key: "forms",
					},
					files: {
						label: "Admin Files",
						icon: "article",
						path: "/dashboard/files",
						admin: true,
						key: "files",
					},
					activity: {
						label: "Activity",
						icon: "query_stats",
						path: "/dashboard/activity",
						admin: true,
						key: "activity",
					},
					users: {
						label: "Users",
						icon: "account_box",
						path: "/dashboard/users",
						admin: true,
						key: "users",
					},
				},
			},
			section: {
				loading: false,
				active: "modules",
				collapse: true,
			},
		},
	}),
	mutations: {
		resizeWindowEvent(state) {
			if (state.window.timeout != null) {
				clearTimeout(state.window.timeout);
			}
			state.window.timeout = setTimeout(function () {
				state.window.width = window.innerWidth;
			}, 100);
		},
		toggleAccountDropdown(state) {
			state.layout.header.accountDropdown =
				!state.layout.header.accountDropdown;
		},
		toggleDropdown(state, val) {
			state.layout.header[val] = !state.layout.header[val];
		},
		toggleNavState(state) {
			state.layout.nav.collapse = !state.layout.nav.collapse;
			if (state.window.width < 600) {
				state.layout.section.collapse = true;
			}
		},
		collapseSection(state, val) {
			state.layout.section.collapse = val;
		},
		setGroup(state, val) {
			state.groups.active = val;
		},
		microsoftSignIn() {
			// let x = this;
			const provider = new OAuthProvider("microsoft.com");

			provider.setCustomParameters({
				tenant: "41900c83-e8b1-4c50-918b-d7134eb5a217",
			});

			const auth = getAuth();
			signInWithPopup(auth, provider)
				.then(async () => {
					// .then((result) => {
					// User is signed in.
					// IdP data available in result.additionalUserInfo.profile.
					// Get the OAuth access token and ID Token
					// const credential = OAuthProvider.credentialFromResult(result);
					// const accessToken = credential.accessToken;
					// const idToken = credential.idToken;
					this.commit("afterSignedIn");
				})
				.catch(() => {
					// Handle error.
				});

			// firebase
			//   .auth()
			//   .signInWithPopup(provider)
			//   .then(function() {
			//     // User is signed in.
			//     // IdP data available in result.additionalUserInfo.profile.
			//     // OAuth access token can also be retrieved:
			//     // result.credential.accessToken
			//     // OAuth ID token can also be retrieved:
			//     // result.credential.idToken
			//     console.log("user signed in!");
			//     router.push("/dashboard");
			//   })
			//   .catch(function(error) {
			//     // Handle error.
			//     console.log(error);
			//   });
		},
		signOut() {
			signOut(auth)
				.then(() => {
					// Sign-out successful.
				})
				.catch(() => {
					// An error happened.
				});
		},
		async afterSignedIn() {
			await this.commit("loadUserData");
			// if(state.groups.active == "SET 5"){
			// if(state.user.stripeCustomerID != null || state.user.stripeCustomerID != ''){
			router.push("/dashboard/assignments");
			// }
			// }
		},
		async loadUserData(state) {
			const uid = auth.currentUser.uid;
			const docRef = doc(db, "users", uid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				const data = docSnap.data();
				state.user = data;
				state.groups.active = state.user.groups[0];
				if (state.user.blockUser) {
					alert(
						"Your account has been disabled. Please contact an administrator to enable your account."
					);
					this.commit("signOut");
				} else {
					// check if paid
					// if(state.groups.active == "SET 5"){
					// 	if(state.user.stripeCustomerID == null || state.user.stripeCustomerID == ''){
					// 		router.push("/submit-payment");
					// 	}
					// }
				}
				// check if paid
				// if(state.groups.active == "SET 5" && !state.user.paid){
				// 	alert('hi');
				// }
			} else {
				// create user
				const uid = auth.currentUser.uid;
				await setDoc(doc(db, "users", uid), {
					displayName: state.user.displayName,
					email: state.user.email,
					photoURL: state.user.photoURL,
					uid: uid,
					groups: ["SET 5"],
					createdOn: new Date(),
					blockUser: false,
				});
				state.groups.active = "SET 5";
			}
		},
		navTo(state, section) {
			if (state.window.width < 600) {
				state.layout.nav.collapse = !state.layout.nav.collapse;
			}
			state.layout.nav.active = section;
			router.push(state.layout.nav.menu[section].path);
			// this.layout.section.collapse = true;
		},
		activateNavPath(state) {
			let path = router.currentRoute.value.path;
			const key = findKey(state.layout.nav.menu, function (item) {
				return startsWith(path, item.path);
			});
			if (key != null) {
				state.layout.nav.active = key;
			}
		},
	},
	actions: {},
	// computed
	getters: {
		isAdmin: (state) => {
			return state.groups.admin.includes(state.user.email);
		},
		navList: (state) => {
			return filter(state.layout.nav.menu, (item) => item.admin == null);
		},
		adminNavList: (state) => {
			return state.layout.nav.menu;
		},
		test() {
			return "hi";
		},
		// doneTodos: state => {
		//   return state.todos.filter(todo => todo.done)
		// }
	},
};

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
	if (user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		// const uid = user.uid;
		// router.push("/dashboard");
		vuex.state.main.user.displayName = user.displayName;
		vuex.state.main.user.email = user.email;
		vuex.state.main.user.photoURL = user.photoURL;
		// console.log(user);
	} else {
		// User is signed out
		if (vuex.state.activity.unsubscribe != null) {
			vuex.state.activity.unsubscribe();
		}
		if (vuex.state.assignments.unsubscribe != null) {
			vuex.state.assignments.unsubscribe();
		}
		if (vuex.state.learning.unsubscribe != null) {
			vuex.state.learning.unsubscribe();
		}
		if (vuex.state.quiz.unsubscribe != null) {
			vuex.state.quiz.unsubscribe();
		}
		if (vuex.state.recordings.unsubscribe != null) {
			vuex.state.recordings.unsubscribe();
		}
		if (vuex.state.referrals.unsubscribe != null) {
			vuex.state.referrals.unsubscribe();
		}
		if (vuex.state.forms.unsubscribeApplication != null) {
			vuex.state.forms.unsubscribeApplication();
		}
		if (vuex.state.forms.unsubscribeInfoSession != null) {
			vuex.state.forms.unsubscribeInfoSession();
		}
		if (vuex.state.forms.unsubscribeContact != null) {
			vuex.state.forms.unsubscribeContact();
		}
		// this.$store.commit("recordings/loadList");
		router.push("/");
	}
});

const vuex = createStore({
	modules: {
		assignments: Assignments,
		activity: Activity,
		forms: Forms,
		learning: Learning,
		recordings: Recordings,
		referrals: Referrals,
		quiz: Quiz,
		main: Store,
	},
});

export default vuex;
